import Author from '../components/author'
import Background from '../components/background'
import Footer from '../components/footer'
import Header from '../components/header'
import HtmlContent from '../components/html-content'
import LatestPosts from '../components/latest-posts'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import Supertitle from '../components/supertitle'
import Wrapper from '../components/wrapper'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import { minWidth } from '../breakpoints'

export default class Page extends React.Component {
  state = {
    noScroll: false,
  }

  render() {
    const {
      data: { page },
    } = this.props
    const content = (
      <div>
        <HtmlContent content={page.content} />
      </div>
    )

    return (
      <Layout>
        <SEO
          title={page.fields.title}
          description={page.fields.seoDescription}
          noScroll={this.state.noScroll}
        />

        <Header onMenuChange={noScroll => this.setState({ noScroll })} />
        <Wrapper
          styles={css`
            max-width: 920px;
            padding-top: 40px;

            ${minWidth.tablet} {
              padding-top: 80px;
            }
          `}
        >
          <Supertitle moreSpace>Josh & Hope</Supertitle>
          <h1>{page.fields.title}</h1>
        </Wrapper>

        {page.fields.showBackground ? (
          <Background>{content}</Background>
        ) : (
          content
        )}
        {page.fields.showAuthor && <Author />}
        {page.fields.showLatestPosts && <LatestPosts />}
        <Footer />
      </Layout>
    )
  }
}

export const query = graphql`
  query PageById($wordpress_id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      content
      fields: wordpress_fields {
        title
        showAuthor
        showLatestPosts
        showBackground
        seoDescription
      }
    }
  }
`
